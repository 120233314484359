import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  IconButton,
  Input,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubNavbar from "../../components/SubNavbar";
import { getUser } from "../../redux/users/userSlice";
import { userServices } from "../../services/userServices";

import SimpleFileUpload from "react-simple-file-upload";
import "./index.scss";
import { filesServices } from "../../services/filesServices";

function AccountDocuments() {
  const dispatch = useDispatch();
  const mobileWidth = window.innerWidth;
  const handleFilePassport = async (url) => {
    await filesServices
      .uploadFile({ fileUrl: url, id: user?.id, fileName: "Passport" })
      .then(() => {
        userServices
          .updateUser(user.id, {
            verifiedPassport: "pending",
          })
          .then(() => {
            dispatch(getUser(user?.id));
          });
      });
  };

  const handleFileAddress = async (url) => {
    await filesServices
      .uploadFile({ fileUrl: url, id: user?.id, fileName: "Proof of address" })
      .then(() => {
        userServices
          .updateUser(user.id, {
            verifiedAddress: "pending",
          })
          .then(() => {
            dispatch(getUser(user?.id));
          });
      });
  };

  const handleFileFunding = async (url) => {
    await filesServices
      .uploadFile({ fileUrl: url, id: user?.id, fileName: "Funding" })
      .then(() => {
        userServices
          .updateUser(user.id, {
            verifiedFunding: "pending",
          })
          .then(() => {
            dispatch(getUser(user?.id));
          });
      });
  };
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );

  return (
    <div className="account-documents-container-parent-root">
      <SubNavbar />
      <div className="account-documents-container-parent">
        <div className="account-documents-container">
          <h1 className="account-documents-header">ACCOUNT DOCUMENTS</h1>

          <div className="account-documents-boxes">
            <div className="account-documents-box">
              <div>
                <h3 className="account-documents-box-header">Passport</h3>
                <p className="account-documents-box-text">
                  Upload one copy both front and back of a current (non-expired)
                  government-issued passport
                </p>
              </div>
              <div className="account-documents-box-buttons">
                {user.verifiedPassport === "verified" ? (
                  <>
                    <Button
                      className="account-documents-button"
                      variant="contained"
                      component="span"
                    >
                      Verified
                    </Button>
                  </>
                ) : user.verifiedPassport === "pending" ? (
                  <>
                    <Button
                      className="account-documents-button"
                      variant="contained"
                      component="span"
                    >
                      Pending
                    </Button>
                  </>
                ) : (
                  <>
                    <SimpleFileUpload
                      apiKey={process.env.REACT_APP_FILE_UPLOAD_KEY}
                      onSuccess={handleFilePassport}
                    />
                    {/* <Button
                      onClick={uploadPassport}
                      className="account-documents-button"
                      variant="contained"
                      component="span"
                    >
                      Upload
                    </Button> */}
                  </>
                )}
              </div>
            </div>
            <div className="account-documents-box">
              <div>
                <h3 className="account-documents-box-header">
                  Proof of address
                </h3>
                <p className="account-documents-box-text">
                  Document to proof of your current residence. Accepted utility
                  bills include: electricity, water, gas or landline phone bill.
                  Please note that we cannot accept mobile phone bills and bank
                  receipts as proof of residency.
                </p>
              </div>
              <div className="account-documents-box-buttons">
                {user.verifiedAddress === "verified" ? (
                  <>
                    <Button
                      className="account-documents-button"
                      variant="contained"
                      component="span"
                    >
                      Verified
                    </Button>
                  </>
                ) : user.verifiedAddress === "pending" ? (
                  <>
                    <Button
                      className="account-documents-button"
                      variant="contained"
                      component="span"
                    >
                      Pending
                    </Button>
                  </>
                ) : (
                  <>
                    <SimpleFileUpload
                      apiKey={process.env.REACT_APP_FILE_UPLOAD_KEY}
                      onSuccess={handleFileAddress}
                    />
                    {/* <Button
                      onClick={uploadAddress}
                      className="account-documents-button"
                      variant="contained"
                      component="span"
                    >
                      Upload
                    </Button> */}
                  </>
                )}
              </div>
            </div>
            <div className="account-documents-box">
              <div>
                <h3 className="account-documents-box-header">Funding</h3>
                <p className="account-documents-box-text">
                  Depending on the payment method you intend to use please
                  provide:
                  <br />
                  1.Credit Card - Submit a copy of both front and back of your
                  credit card. We need to see the last 6 digits and we DO NOT
                  need to see the CVV.
                  <br />
                  2.Bank Wire Transfer - Submit a copy of the transfer receipt
                  and your bank statement.
                </p>
              </div>
              <div className="account-documents-box-buttons">
                {user.verifiedFunding === "verified" ? (
                  <>
                    <Button
                      className="account-documents-button"
                      variant="contained"
                      component="span"
                    >
                      Verified
                    </Button>
                  </>
                ) : user.verifiedFunding === "pending" ? (
                  <>
                    <Button
                      className="account-documents-button"
                      variant="contained"
                      component="span"
                    >
                      Pending
                    </Button>
                  </>
                ) : (
                  <>
                    <SimpleFileUpload
                      apiKey={process.env.REACT_APP_FILE_UPLOAD_KEY}
                      onSuccess={handleFileFunding}
                    />
                    {/* <Button
                      onClick={uploadFunding}
                      className="account-documents-button"
                      variant="contained"
                      component="span"
                    >
                      Upload
                    </Button> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountDocuments;
