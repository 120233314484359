import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import "./index.scss";
import { userServices } from "../../../../../../../services/userServices";
import DateTimePickerComponent from "../../../../../../../components/datePicker";
import dayjs from "dayjs";
import { transactionService } from "../../../../../../../services/transactionServices";
import { getAllTransactions } from "../../../../../../../redux/transactions/transactionsSlice";

const EditTransactionHistory = ({
  transaction,
  setModalOpen,
  user,
  rowsPerPage,
  page,
}) => {
  const users = useSelector((state) => state.users.users);
  const currentUser = users?.filter(
    (user) => user?.id === transaction?.userId
  )[0];
  const [transactionDescription, setTransactionDescription] = React.useState(
    transaction.description
  );

  const [transactionAmount, setTransactionAmount] = React.useState(
    transaction.amount
  );
  const [createdAt, setCreatedAt] = React.useState(
    dayjs(
      new Date(Number(transaction.createdAt)).toLocaleString("sv-SE", {
        timeZone: "Europe/Stockholm",
      })
    )
  );
  const [disableSaveButton, setDisableSaveButton] = React.useState(true);
  const dispatch = useDispatch();

  const handleChangeTransactionDescription = (e) => {
    const value = e.target.value;
    setTransactionDescription(value);
    setDisableSaveButton(false);
  };
  const handleTransactionAmountChange = (e) => {
    const value = e.target.value;
    setTransactionAmount(value);
    setDisableSaveButton(false);
  };

  const handleOnSave = () => {
    transactionService
      .updateTransaction(transaction?.id, {
        createdAt:
          typeof createdAt === "object" ? Date.parse(createdAt) : createdAt,
        amount: transactionAmount,
        description: transactionDescription,
      })
      .then(() => {
        // userServices
        //   .updateUser(currentUser?.id, {
        //     ballance:
        //       Number(currentUser?.ballance) -
        //       Number(transaction.amount) +
        //       Number(transactionAmount),
        //   })
        // .then(() => {
        const payload = {
          rowsPerPage,
          page: page + 1,
          ...(!!user && {
            userFullName: user?.firstName + " " + user?.lastName,
          }),
        };
        setDisableSaveButton(true);
        dispatch(getAllTransactions(payload));

        setModalOpen(false);
      });
    // });
  };
  const transactionDescriptions = [
    "Deposit via CC",
    "Deposit via Bank Transfer",
    "Deposit via Crypto",
    "Liquidity advance",
    "Bonus",
  ];
  return (
    <div
      className="edit-transaction-wrap"
      style={{ top: "25%", alignItems: "center" }}
    >
      <div className="edit-transaction-top">
        <h2
          style={{
            padding: "10px",
            fontSize: "1.6rem",
            textAlign: "center",
            fontWeight: "normal",
          }}
        >
          Edit Transaction
        </h2>
        <CloseIcon
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "5%",
            right: "5%",
            fontSize: "1.7rem",
          }}
          onClick={() => setModalOpen(false)}
        />
      </div>
      <DateTimePickerComponent
        setDisableSaveButton={setDisableSaveButton}
        isEditUser={true}
        label={"Created At"}
        date={createdAt}
        setDate={setCreatedAt}
      />
      <TextField
        style={{
          height: "34px",
          margin: "12px 2px",
          width: "220px",
          backgroundColor: "#1F2A40",
        }}
        className="edit-transaction-textField"
        label="Amount"
        value={transactionAmount}
        onChange={(e) => handleTransactionAmountChange(e)}
        name="amount"
        InputLabelProps={{
          style: {
            color: "white",
            fontStyle: "normal",
            fontSize: "0.8rem",
            top: "-10%",
            border: "none",
          },
        }}
        InputProps={{
          style: { color: "white", backgroundColor: "#1F2A40" },
        }}
        inputProps={{
          style: { border: "none" },
        }}
      />

      <FormControl style={{ width: "220px" }}>
        <InputLabel style={{ color: "white", fontSize: "0.8rem", top: "25%" }}>
          Description
        </InputLabel>
        <Select
          style={{
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            height: "44px",
            margin: "22px 2px",
            backgroundColor: "#1F2A40",
          }}
          value={transactionDescription}
          label="Description"
          name="description"
          onChange={(e) => handleChangeTransactionDescription(e)}
        >
          {transactionDescriptions.map((v) => (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="edit-transaction-button-wrap">
        <Button
          disabled={disableSaveButton}
          style={{ width: "100px" }}
          color="success"
          variant="contained"
          onClick={() => handleOnSave()}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditTransactionHistory;
