import { Button, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { transactionService } from "../../../../../../services/transactionServices";
import { userServices } from "../../../../../../services/userServices";
import "./index.scss";
import DateTimePicker from "../../../../../../components/datePicker";
import DateTimePickerComponent from "../../../../../../components/datePicker";
import dayjs from "dayjs";
import { getUserBalance } from "../../../../../../redux/users/userSlice";
import { getAllRegisteredUsers } from "../../../../../../redux/allRegisteredUsers/allRegisteredUsersSlice";
export const SimulatedTransactionHistory = ({
  user,
  setEditButtonOptions,
  setUserDetails,
  setOpenModal,
  fetchUsersPayload,
}) => {
  const [simulatedTransaction, setSimulatedTransaction] = React.useState({
    createdAt: "",
    description: "",
    amount: "",
    beforeTransaction: user.balance,
    afterTransaction: "",
    userId: user.id,
  });

  const reduxUser = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  const [isMounted, setIsMounted] = React.useState(true); // Flag to track if component is mounted

  useEffect(() => {
    // Mark the component as mounted when it's first rendered
    setIsMounted(true);

    // Cleanup function to mark the component as unmounted
    return () => {
      setIsMounted(false);
    };
  }, []);
  const loggedInUserRole = reduxUser?.role;
  const loggedInUserName =
    reduxUser?.role !== "admin" &&
    reduxUser?.role !== "head_of_conversion" &&
    reduxUser?.role !== "crm_manager"
      ? reduxUser?.firstName + " " + reduxUser?.lastName
      : "";
  const dispatch = useDispatch();
  const [createdAt, setCreatedAt] = React.useState(
    dayjs(
      new Date().toLocaleString("sv-SE", {
        timeZone: "Europe/Stockholm",
      })
    )
  );
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSimulatedTransaction({
      ...simulatedTransaction,
      [e.target.name]: value,
    });
  };
  const toTimeStamp = (strDate) => {
    let date = Date.parse(strDate);
    return date;
  };
  const handleOnSave = () => {
    transactionService.createTransaction({
      createdAt:
        typeof createdAt === "object" ? Date.parse(createdAt) : createdAt,

      description: simulatedTransaction.description,
      amount: simulatedTransaction.amount,
      beforeTransaction: user.ballance,
      afterTransaction: (
        Number(user.ballance) + Number(simulatedTransaction.amount)
      )
        .toFixed(2)
        .toString(),
      userId: user.id,
    });
    userServices
      .changeUserBallance(user.id, {
        ballance: (Number(user.ballance) + Number(simulatedTransaction.amount))
          .toFixed(2)
          .toString(),
      })
      .then(() => {
        if (isMounted) {
          dispatch(getAllRegisteredUsers(fetchUsersPayload));
          setUserDetails({
            ballance: user.ballance,
          });
          setOpenModal(false);
        }
      });
    setEditButtonOptions("");
  };
  const description = [
    "Deposit via CC",
    "Deposit via Bank Transfer",
    "Deposit via Crypto",
    "Liquidity advance",
    "Bonus",
  ];
  return (
    <div className="simulate-transaction-container">
      <div>
        <DateTimePickerComponent
          date={createdAt}
          setDate={setCreatedAt}
          label={"Created At"}
        />
        {/* <TextField
          className="edit-user-textField"
          label="Created At"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          style={{ margin: "12px 2px", width: "150px" }}
          name="createdAt"
          placeholder="Ex: 1.1.2020 00:00:00"
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        /> */}
        {/* <TextField
          className="edit-user-textField"
          label="Description"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          name="description"
          style={{ margin: "12px 2px", width: "150px" }}
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        /> */}
        <FormControl style={{ minWidth: "200px" }}>
          <InputLabel style={{ color: "white", marginTop: "3%" }}>
            Description
          </InputLabel>
          <Select
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              height: "35px",

              margin: "12px 2px",
              // border: "1px solid gray",
              borderRadius: "2px",
              backgroundColor: "#1F2A40",
            }}
            label="Description"
            name="description"
            onChange={(e) => handleInputChange(e)}
          >
            {description.map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          className="edit-user-textField"
          label="Amount"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          name="amount"
          style={{ margin: "12px 2px", width: "150px" }}
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        />
        <TextField
          className="edit-user-textField"
          label="Before Transaction"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          value={user.ballance}
          style={{ margin: "12px 2px", width: "150px" }}
          name="beforeTransaction"
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        />
        <TextField
          className="edit-user-textField"
          label="After Transaction"
          onChange={(e) => handleInputChange(e)}
          style={{ margin: "12px 2px", width: "150px" }}
          variant="outlined"
          value={Number(user.ballance) + Number(simulatedTransaction.amount)}
          name="afterTransaction"
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        />
      </div>
      <Button
        style={{ width: "200px", margin: "0 auto", marginBottom: "2%" }}
        color="success"
        variant="outlined"
        disabled={!user.ballance}
        onClick={() => handleOnSave()}
      >
        Save Transaction
      </Button>
    </div>
  );
};
