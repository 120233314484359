import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const EmbededChart = () => {
  const state = useSelector((state) => state.cryptoMarketDataReducer);
  const mobileWidth = window.innerWidth;
  const container = useRef();
  const [widget, setWidget] = useState(null);

  useEffect(() => {
    // Remove the old widget (if it exists)
    if (widget) {
      widget.remove();
    }

    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
      {
        "timezone": "Etc/UTC",
        "autosize": "true",
        "symbol": "${state.symbol}",
        "interval": "D",
        "theme": "dark",
        "style": "1",
        "locale": "en",
        "hide_top_toolbar": "${mobileWidth <= 657 ? true : false}",
        "withdateranges": "${mobileWidth <= 657 ? false : true}",
        "allow_symbol_change": "true",
        "calendar": "false",
        "support_host": "https://www.tradingview.com",
        "width": "${
          mobileWidth <= 667
            ? "100%"
            : mobileWidth >= 1050 && mobileWidth <= 1300
            ? "95%"
            : "95%"
        }",
        "height": "${mobileWidth <= 657 ? 500 : 560}",
        "toolbar_bg": "rgba(102,102,102,0.4)",
        "hide_side_toolbar": "${mobileWidth <= 657 ? true : false}",
        "allow_symbol_change": "true"
      }`;

    // Append the script to the container and store the widget
    container.current.appendChild(script);
    setWidget(script); // Store the widget reference so it can be removed later

    // Cleanup function to remove widget on component unmount or symbol change
    return () => {
      if (container.current) {
        container.current.innerHTML = ""; // Clear previous widget
      }
    };
  }, [state.symbol]); // Re-run the effect only when the symbol changes

  return (
    <div
      className="tradingview-widget-container"
      ref={container}
      style={{ margin: "auto", boxShadow: "0px -2px 17px 6px" }}
    >
      <div
        className="tradingview-widget-container__widget"
        style={{ border: "1px solid" }}
        // style={{ height: "calc(100% - 32px)", width: "100%" }}
      ></div>
      <div className="tradingview-widget-copyright">
        <a
          href="https://www.tradingview.com/"
          rel="noopener nofollow"
          target="_blank"
        >
          {/* <span className="blue-text">Track all markets on TradingView</span> */}
        </a>
      </div>
    </div>
  );
};

export default React.memo(EmbededChart);
