import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withdrawHistoryService } from "../../../../../../../services/withdrawHistoryServices";
import CloseIcon from "@mui/icons-material/Close";
import { getAllWithdrawHistory } from "../../../../../../../redux/withdrawHistory/withdrawHistorySlice";
import "./index.scss";
import { userServices } from "../../../../../../../services/userServices";
import DateTimePickerComponent from "../../../../../../../components/datePicker";
import dayjs from "dayjs";

const EditWithdrawHistory = ({
  withdraw,
  setModalOpen,
  user,
  page,
  rowsPerPage,
}) => {
  const users = useSelector((state) => state.users.users);
  const currentUser = users?.filter((user) => user?.id === withdraw?.userId)[0];
  const [withdrawStatus, setWithdrawStatus] = React.useState(withdraw.status);
  const [withdrawDescription, setWithdrawDescription] = React.useState(
    withdraw.description
  );
  const [withdrawAmount, setWithdrawAmount] = React.useState(withdraw.amount);
  const [createdAt, setCreatedAt] = React.useState(
    dayjs(
      new Date(Number(withdraw.createdAt)).toLocaleString("sv-SE", {
        timeZone: "Europe/Stockholm",
      })
    )
  );
  const [disableSaveButton, setDisableSaveButton] = React.useState(true);
  const dispatch = useDispatch();
  const withdrawStatuses = ["", "Pending", "Approved", "Declined"];
  const handleChangeWithdrawStatus = (e) => {
    const value = e.target.value;
    setWithdrawStatus(value);
    setDisableSaveButton(false);
  };
  const handleChangeWithdrawDescription = (e) => {
    const value = e.target.value;
    setWithdrawDescription(value);
    setDisableSaveButton(false);
  };
  const handleWithdrawAmountChange = (e) => {
    const value = e.target.value;
    setWithdrawAmount(value);
    setDisableSaveButton(false);
  };

  const handleOnSave = () => {
    withdrawHistoryService
      .updateWithdrawHistory(withdraw?.id, {
        createdAt:
          typeof createdAt === "object" ? Date.parse(createdAt) : createdAt,
        amount: withdrawAmount,
        status: withdrawStatus,
        description: withdrawDescription,
      })
      .then(() => {
        // if (withdrawStatus === "Approved") {
        //   userServices.updateUser(withdraw?.userId, {
        //     ballance: Number(currentUser?.ballance) - Number(withdrawAmount),
        //   });
        // }

        const payload = {
          rowsPerPage,
          page: page + 1,
          ...(!!user && {
            userFullName: user?.firstName + " " + user?.lastName,
          }),
        };
        setDisableSaveButton(true);
        dispatch(getAllWithdrawHistory(payload));
        setModalOpen(false);
      });
  };
  return (
    <div
      className="edit-withdraw-wrap"
      style={{ top: "25%", alignItems: "center" }}
    >
      <div className="edit-withdraw-top">
        <h2
          style={{
            padding: "10px",
            fontSize: "1.6rem",
            textAlign: "center",
            fontWeight: "normal",
          }}
        >
          Edit Withdraw
        </h2>
        <CloseIcon
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "5%",
            right: "5%",
            fontSize: "1.7rem",
          }}
          onClick={() => setModalOpen(false)}
        />
      </div>
      <DateTimePickerComponent
        setDisableSaveButton={setDisableSaveButton}
        isEditUser={true}
        label={"Created At"}
        date={createdAt}
        setDate={setCreatedAt}
      />
      <TextField
        style={{
          height: "34px",
          margin: "12px 2px",
          width: "220px",
          backgroundColor: "#1F2A40",
        }}
        className="edit-withdraw-textField"
        label="Amount"
        value={withdrawAmount}
        onChange={(e) => handleWithdrawAmountChange(e)}
        name="amount"
        InputLabelProps={{
          style: {
            color: "white",
            fontStyle: "normal",
            fontSize: "0.8rem",
            top: "-10%",
            border: "none",
          },
        }}
        InputProps={{
          style: { color: "white", backgroundColor: "#1F2A40" },
        }}
        inputProps={{
          style: { border: "none" },
        }}
      />
      <TextField
        style={{
          height: "34px",
          margin: "22px 2px",
          width: "220px",
          backgroundColor: "#1F2A40",
        }}
        className="edit-withdraw-textField"
        label="Description"
        value={withdrawDescription}
        onChange={(e) => handleChangeWithdrawDescription(e)}
        name="withdrawDescription"
        InputLabelProps={{
          style: {
            color: "white",
            fontStyle: "normal",
            fontSize: "0.8rem",
            top: "-10%",
            border: "none",
          },
        }}
        InputProps={{
          style: { color: "white", backgroundColor: "#1F2A40" },
        }}
        inputProps={{
          style: { border: "none" },
        }}
      />
      <FormControl style={{ width: "220px" }}>
        <InputLabel style={{ color: "white", fontSize: "0.8rem", top: "25%" }}>
          Change Withdraw Status
        </InputLabel>
        <Select
          style={{
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            height: "34px",
            margin: "22px 2px",
            backgroundColor: "#1F2A40",
          }}
          value={withdrawStatus}
          label="Withdraw Status"
          name="withdrawStatus"
          onChange={(e) => handleChangeWithdrawStatus(e)}
        >
          {withdrawStatuses.map((v) => (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="edit-withdraw-button-wrap">
        <Button
          disabled={disableSaveButton}
          style={{ width: "100px" }}
          color="success"
          variant="contained"
          onClick={() => handleOnSave()}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default EditWithdrawHistory;
