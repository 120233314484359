import { Box, Button } from "@material-ui/core";
import { useNavigate } from "react-router";

import "./index.scss";
import { Slide } from "react-awesome-reveal";

const ToolsPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        style={{ paddingBottom: "5%" }}
        className={
          window.location.href.includes("tools")
            ? "tools-page-container-landing"
            : "tools-page-container"
        }
      >
        <Slide duration={2000}>
          <Box className="tools-types-container">
            <Box className="tools-types-risk-img">
              <img src="icon-zero-commission.svg" alt="zero commission icon" />
            </Box>
            <Box className="tools-types-wrapper">
              <h1 className="tools-types-headers">Almost Zero Commission</h1>
              <span className="tools-types-spans">
                Welcome to global markets with endless trading opportunities.{" "}
                <br />
                At Nordenex, you can explore these markets <br />
                without paying any commission fees.
              </span>
              <br />
              <br />
              <span className="tools-types-spans">
                Zero commission is a special feature for Nordenex clients,{" "}
                <br />
                excluding short or leveraged stock trades. It means that there
                are <br />
                no broker fees when opening or closing positions. <br />
                However, other fees might still apply.
              </span>
              <br />
              <br />
              <div className="button-wrap">
                <Button
                  variant="outlined"
                  color="inherit"
                  className="tools-buttons"
                  onClick={() => navigate("/sign-up")}
                >
                  Join Now
                </Button>
              </div>
            </Box>
          </Box>
        </Slide>

        <Slide duration={2000} direction="right">
          <Box className="tools-types-container-2">
            <Box style={{ marginTop: "30px" }} className="tools-types-risk-img">
              <img src="icon-risk-management.svg" alt="risk management icon" />
            </Box>
            <Box className="tools-types-wrapper">
              <h1 className="tools-types-headers">Advanced Risk Management</h1>
              <span className="tools-types-spans">
                We offer a unique blend of technical and fundamental analysis,{" "}
                <br />
                tailored account types, instant trade execution, and the best{" "}
                <br />
                software tools available for risk management.
              </span>
              <br />
              <br />
              <span className="tools-types-spans">
                With features like Stop Loss/Take Profit, you can manage your{" "}
                <br />
                losses and profits by setting predetermined levels.
              </span>
              <br />
              <br />
              <span className="tools-types-spans">
                Managing risk is key to any successful investment strategy.{" "}
                <br />
                Be sure to use Risk Management Tools in conjunction with advice{" "}
                <br />
                from your dedicated Financial Analyst.
              </span>
              <br />
              <br />

              <div className="button-wrap">
                <Button
                  color="inherit"
                  variant="outlined"
                  className="tools-buttons"
                  onClick={() => navigate("/sign-up")}
                >
                  Join Now
                </Button>
              </div>
            </Box>
          </Box>
        </Slide>
      </Box>
    </>
  );
};

export default ToolsPage;
