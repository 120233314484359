import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TickerTape } from "react-tradingview-embed";
import SubNavbar from "../../components/SubNavbar";
import { getUser } from "../../redux/users/userSlice";
import CryptoCurrenciesWebSocket from "./components/CryptoCurrenciesWebSocket";
import TradeMarket from "./components/TradeMarket";
import Widgets from "./components/Widgets";

import "./index.scss";
function TradePage() {
  const dispatch = useDispatch();
  const user = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );
  React.useEffect(() => {
    dispatch(getUser(user.id));
  }, []);

  const containerRef = useRef(null);

  React.useEffect(() => {
    // Ensure that the widget is only loaded once
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.async = true;

    // Widget configuration
    script.innerHTML = `
      {
        "symbols": [
          {
            "proName": "FOREXCOM:SPXUSD",
            "title": "S&P 500 Index"
          },
          {
            "proName": "FOREXCOM:NSXUSD",
            "title": "US 100 Cash CFD"
          },
          {
            "proName": "FX_IDC:EURUSD",
            "title": "EUR to USD"
          },
          {
            "proName": "BITSTAMP:BTCUSD",
            "title": "Bitcoin"
          },
          {
            "proName": "BITSTAMP:ETHUSD",
            "title": "Ethereum"
          }
        ],
        "showSymbolLogo": true,
        "isTransparent": false,
        "displayMode": "adaptive",
        "colorTheme": "dark",
        "locale": "en"
      }
    `;

    // Append the script to the container
    containerRef.current.appendChild(script);

    // Cleanup function to remove script on component unmount
    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = ""; // Clear previous widget
      }
    };
  }, []);

  return (
    <div className="trade-page-container">
      <div className="trade-page-subcontainer">
        <SubNavbar></SubNavbar>

        <div className="trading-widgets-container">
          {window.innerWidth <= 658 ? (
            <>
              <div className="trading-wrap">
                <div className="widgets-market">
                  <Widgets />
                </div>
                {/* <Orders /> */}
              </div>
              <CryptoCurrenciesWebSocket />
              <TradeMarket />
            </>
          ) : (
            <>
              <CryptoCurrenciesWebSocket />
              <div className="trading-wrap">
                <div className="widgets-market">
                  <Widgets />
                </div>
                {/* <Orders /> */}
              </div>
              <TradeMarket />
            </>
          )}
        </div>
        {/* <div></div> */}
        {/* <br></br> */}
        {/* <br></br> */}
        {/* <br></br> */}
        {/* <div className="ticker-container"> */}
        <div
          style={{
            position: "fixed",
            bottom: 0,
            zIndex: 99999,
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            borderTop: "1px solid rgba(255, 255, 255, 0.3) !important",
          }}
          className="tradingview-widget-container"
          ref={containerRef}
        >
          <div
            style={{ height: "50px !important" }}
            className="tradingview-widget-container__widget"
          ></div>
          <div className="tradingview-widget-copyright">
            <a
              href="https://www.tradingview.com/"
              rel="noopener nofollow"
              target="_blank"
            >
              {/* <span className="blue-text">
                Track all markets on TradingView
              </span> */}
            </a>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default TradePage;
