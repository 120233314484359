import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllRegisteredUsers } from "../../../../../../redux/allRegisteredUsers/allRegisteredUsersSlice";
import { orderServices } from "../../../../../../services/orderServices";
import React, { useEffect } from "react";
import { userServices } from "../../../../../../services/userServices";
import {
  getUser,
  getUserBalance,
} from "../../../../../../redux/users/userSlice";
import "./index.scss";
import DateTimePickerComponent from "../../../../../../components/datePicker";
import dayjs from "dayjs";
export const OpenRealTimePosition = ({
  user,
  setEditButtonOptions,
  setOpenModal,
  setUserDetails,
  fetchUsersPayload,
}) => {
  const dispatch = useDispatch();
  const leverageTypes = [1, 5, 15, 30, 60, 90, 120];
  const assetTypes = [
    "BTCUSDT",
    "ETHUSDT",
    "BNBUSDT",
    "NEOUSDT",
    "LTCUSDT",
    "ADAUSDT",
    "XRPUSDT",
    "EOSUSDT",
    "IOTAUSDT",
    "XLMUSDT",
    "TRXUSDT",
    "DASHUSDT",
    "POLUSDT",
    "DOGEUSDT",
    "SOLUSDT",
    "CELOUSDT",
    "SHIBUSDT",
    "DOTUSDT",
    "APTUSDT",
    "TONUSDT",
  ];
  const [createdAt, setCreatedAt] = React.useState(
    dayjs(
      new Date().toLocaleString("sv-SE", {
        timeZone: "Europe/Stockholm",
      })
    )
  );
  const reduxUser = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );

  const loggedInUserRole = reduxUser?.role;
  const loggedInUserName =
    reduxUser?.role !== "admin" &&
    reduxUser?.role !== "head_of_conversion" &&
    reduxUser?.role !== "crm_manager"
      ? reduxUser?.firstName + " " + reduxUser?.lastName
      : "";
  const [realTimeOrder, setRealTimeOrder] = React.useState({
    createdAt: "",
    openPrice: "",
    assetType: "BTCUSDT",
    quantity: "",
    type: "market",
    takeProfit: "",
    stopLoss: "",
    leverage: 1,
    buyOrSell: "buy",
    investmentAmount: "",
  });
  const profit =
    realTimeOrder.buyOrSell.toLowerCase() === "buy"
      ? isNaN(
          realTimeOrder.closedPrice - Number(realTimeOrder.openPrice).toFixed(2)
        )
        ? "---"
        : (
            realTimeOrder.closedPrice -
            Number(realTimeOrder.openPrice).toFixed(2)
          ).toFixed(2)
      : isNaN(
          Number(realTimeOrder.openPrice).toFixed(2) - realTimeOrder.closedPrice
        )
      ? "---"
      : (
          Number(realTimeOrder.openPrice).toFixed(2) - realTimeOrder.closedPrice
        ).toFixed(2);
  const handleInputChange = (e) => {
    const value = e.target.value;
    setRealTimeOrder({
      ...realTimeOrder,
      [e.target.name]: value,
    });
  };
  const toTimeStamp = (strDate) => {
    let date = Date.parse(strDate);
    return date;
  };

  const [isMounted, setIsMounted] = React.useState(true); // Flag to track if component is mounted

  useEffect(() => {
    // Mark the component as mounted when it's first rendered
    setIsMounted(true);

    // Cleanup function to mark the component as unmounted
    return () => {
      setIsMounted(false);
    };
  }, []);
  const handleOnSave = () => {
    orderServices
      .createOrder({
        createdAt:
          typeof createdAt === "object" ? Date.parse(createdAt) : createdAt,
        openPrice: realTimeOrder.openPrice,
        assetType: realTimeOrder.assetType,
        quantity: realTimeOrder.quantity,
        leverage: realTimeOrder.leverage,
        stopLoss: realTimeOrder.stopLoss,
        takeProfit: realTimeOrder.takeProfit,
        type: realTimeOrder.type,
        buyOrSell: realTimeOrder.buyOrSell,
        investmentAmount: realTimeOrder.investmentAmount,
        userId: user.id,
        balanceBeforeOrder: user.ballance,
      })
      .then(() => {
        dispatch(getAllRegisteredUsers(fetchUsersPayload));
      });
    userServices
      .updateUser(user.id, {
        ballance: (
          Number(user.ballance) - Number(realTimeOrder.investmentAmount)
        ).toString(),
      })
      .then(() => {
        if (isMounted) {
          dispatch(getUserBalance(user.id));
          setUserDetails({
            ballance: user.ballance,
          });
          setOpenModal(false);
        }
      });
    // .then(() => {
    //   dispatch(getUser(user.id));
    // });
    setEditButtonOptions("");
  };
  return (
    <div className="open-realTime-position-container">
      <div>
        <DateTimePickerComponent
          label={"Created At"}
          date={createdAt}
          setDate={setCreatedAt}
        />
        <TextField
          style={{ margin: "12px 2px", width: "150px" }}
          className="edit-user-textField"
          label="Open Price"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          name="openPrice"
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        />
        <FormControl style={{ width: "150px" }}>
          <InputLabel style={{ color: "white", marginTop: "3%" }}>
            Asset Type
          </InputLabel>
          <Select
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              height: "35px",

              margin: "12px 2px",
              // border: "1px solid gray",
              borderRadius: "2px",
              backgroundColor: "#1F2A40",
            }}
            label="Asset Type"
            name="assetType"
            defaultValue={"BTCUSDT"}
            onChange={(e) => handleInputChange(e)}
          >
            {assetTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          style={{ margin: "12px 2px", width: "150px" }}
          className="edit-user-textField"
          label="Quantity"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          name="quantity"
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        />

        <FormControl style={{ width: "150px" }}>
          <InputLabel style={{ color: "white", marginTop: "3%" }}>
            Leverage
          </InputLabel>
          <Select
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              height: "35px",

              margin: "12px 2px",
              // border: "1px solid gray",
              backgroundColor: "#1F2A40",
              borderRadius: "2px",
            }}
            label="Leverage"
            name="leverage"
            defaultValue={1}
            onChange={(e) => handleInputChange(e)}
          >
            {leverageTypes.map((leverage) => (
              <MenuItem key={leverage} value={leverage}>
                {leverage}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div>
        <TextField
          style={{ margin: "12px 2px", width: "150px" }}
          className="edit-user-textField"
          label="Stop Loss"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          name="stopLoss"
          InputLabelProps={{
            style: {
              color: "white",
              margin: "-5% auto",
              textAlign: "center",
              alignItems: "center",
            },
          }}
        />
        <TextField
          style={{ margin: "12px 2px", width: "150px" }}
          className="edit-user-textField"
          label="Take Profit"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          name="takeProfit"
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        />
        <FormControl style={{ width: "150px" }}>
          <InputLabel style={{ color: "white", marginTop: "3%" }}>
            Order Type
          </InputLabel>
          <Select
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              height: "35px",

              margin: "12px 2px",
              // border: "1px solid gray",
              borderRadius: "2px",
              backgroundColor: "#1F2A40",
            }}
            label="Order Type"
            name="type"
            defaultValue={"market"}
            onChange={(e) => handleInputChange(e)}
          >
            {["market", "limit"].map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={{ width: "150px" }}>
          <InputLabel style={{ color: "white", marginTop: "3%" }}>
            Buy or Sell
          </InputLabel>
          <Select
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              height: "35px",

              margin: "12px 2px",
              // border: "1px solid gray",
              backgroundColor: "#1F2A40",
              borderRadius: "2px",
            }}
            label="Buy or Sell"
            name="buyOrSell"
            defaultValue={"buy"}
            onChange={(e) => handleInputChange(e)}
          >
            {["buy", "sell"].map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          style={{ margin: "12px 2px", width: "150px" }}
          className="edit-user-textField"
          label="Investment Amount"
          onChange={(e) => handleInputChange(e)}
          variant="outlined"
          name="investmentAmount"
          InputLabelProps={{
            style: { color: "white", margin: "-5% auto" },
          }}
        />
      </div>
      <Button
        style={{ width: "250px", margin: "0 auto", marginBottom: "2%" }}
        variant="outlined"
        color="success"
        onClick={() => handleOnSave()}
      >
        Open Position
      </Button>
    </div>
  );
};
