import { Box } from "@material-ui/core";

import "./index.scss";
import { Flip, Slide } from "react-awesome-reveal";

const AboutUsPage = () => {
  return (
    <>
      <Box className="about-us-page-container">
        <Slide duration={2000}>
          <Box className="about-us-types-container">
            <Box className="about-us-images-wrapper">
              <img src="icon-mission.svg" width={"330px"} alt="icon mission" />
            </Box>
            <Box>
              <h1 className="about-us-types-headers">
                Empowering Your Trading Journey
              </h1>
              <span className="about-us-types-spans">
                Our mission is to help both newcomers and seasoned professionals
                excel in online trading. We provide tailored solutions that
                include a wide range of educational resources, such as on-demand
                seminars, live webinars, daily news updates, expert market
                analysis, and much more. <br />
                We are here to support you at every step, ensuring that your
                financial goals are achieved.
              </span>
            </Box>
          </Box>
        </Slide>

        <Slide duration={2000} direction="right">
          <Box className="about-us-types-container-reverse">
            <Box className="risk-text">
              <h1 className="about-us-types-headers">Strategic Risk Control</h1>
              <span className="about-us-types-spans">
                At Nordenex, we've been guiding investors since 2011 with
                outstanding results. Our team of expert traders and financial
                advisors are equipped to navigate the complexities of high-risk
                markets. <br />
                Through years of experience, we have developed risk management
                strategies that maximize potential returns while minimizing
                potential losses. <br />
                Our profits are fairly distributed between our investment fund,
                private investors, and clients.
              </span>
            </Box>
            <Box className="about-us-images-wrapper">
              <img src="icon-risk-management.svg" alt="icon risk" />
            </Box>
          </Box>
        </Slide>

        <Slide duration={2000}>
          <Box className="about-us-types-container">
            <Box className="about-us-images-wrapper">
              <img src="icon-team.svg" width={"330px"} alt="icon team" />
            </Box>
            <Box>
              <h1 className="about-us-types-headers">
                Client-Centric Relationship
              </h1>
              <span className="about-us-types-spans">
                Our dedicated team of Financial Analysts is committed to
                offering personalized support to each of our clients. <br />
                Building strong, long-lasting relationships is at the core of
                our philosophy. <br />
                We understand that providing top-tier service and precise
                attention to detail is crucial to successfully managing
                investments and portfolios. <br />
                We are here to help you achieve your financial objectives.
              </span>
            </Box>
          </Box>
        </Slide>

        <Slide direction="right" duration={2000}>
          <Box className="about-us-types-container-reverse">
            <Box>
              <h1 className="about-us-types-headers">
                Uncompromising Security
              </h1>
              <span className="about-us-types-spans">
                Your security is paramount to us. <br />
                We understand the importance of protecting your data and
                investments in today’s digital world. <br />
                Our platform implements advanced security measures including SSL
                encryption, PCI DSS compliance, Comodo certification, and
                peer-to-peer encryption to safeguard your trading experience.{" "}
                <br />
                Rest assured, your personal and financial information is in safe
                hands with us.
              </span>
            </Box>
            <Box className="about-us-images-wrapper">
              <img src="icon-shield.svg" width={"330px"} alt="icon shield" />
            </Box>
          </Box>
        </Slide>
      </Box>
    </>
  );
};

export default AboutUsPage;
