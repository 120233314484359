import { Box } from "@material-ui/core";
import "./index.scss";
import { Slide } from "react-awesome-reveal";

const FeaturesPage = () => {
  return (
    <>
      <Box
        style={{
          marginTop: window.location.href.includes("features") ? "3rem" : "0",
        }}
        className="features-page-container"
      >
        <Box className="features-page-textBox">
          <h3 className="features-page-h3">What do we offer?</h3>
          <Box className="features-page-span">
            <span>
              At Nordenex, we strive to provide our clients with the most
              effective tools and services to ensure their success in the
              market.
            </span>
          </Box>
        </Box>
        <Box className="features-types-container">
          <Slide duration={2000}>
            <Box className="features-types-single-container">
              <img src="icon-wallet.svg" width={"140px"} alt="wallet icon" />
              <h2 className="features-types-headers">Secure Wallet</h2>
              <span className="features-types-spans">
                Store your funds with confidence using our cutting-edge <br />
                security technology to keep them safe.
              </span>
            </Box>
          </Slide>
          <Slide duration={2000}>
            <Box className="features-types-single-container">
              <img src="icon-advisor.svg" width={"140px"} alt="advisor icon" />
              <h2 className="features-types-headers">
                Personal Financial Advisor
              </h2>
              <span className="features-types-spans">
                Have a dedicated financial expert to help you navigate the{" "}
                <br />
                markets and make informed decisions.
              </span>
            </Box>
          </Slide>
          <Slide duration={2000}>
            <Box className="features-types-single-container">
              <img
                src="icon-portfolio.svg"
                width={"140px"}
                alt="portfolio icon"
              />
              <h2 className="features-types-headers">
                Comprehensive Portfolio Management
              </h2>
              <span className="features-types-spans">
                An all-in-one platform that helps you align your investments{" "}
                <br />
                with your financial goals and objectives.
              </span>
            </Box>
          </Slide>
        </Box>
        <Box className="features-types-container">
          <Slide duration={2000} direction="right">
            <Box className="features-types-single-container">
              <img
                src="icon-financial-tools.svg"
                width={"140px"}
                alt="tools icon"
              />
              <h2 className="features-types-headers">Advanced Market Tools</h2>
              <span className="features-types-spans">
                Use innovative tools to minimize risks and maximize your <br />
                potential returns with our advanced Risk Management features.
              </span>
            </Box>
          </Slide>
          <Slide duration={2000} direction="right">
            <Box className="features-types-single-container">
              <img src="icon-support.svg" width={"140px"} alt="support icon" />
              <h2 className="features-types-headers">24/5 Customer Support</h2>
              <span className="features-types-spans">
                Our expert support team is available via phone and email <br />
                to assist you whenever you need help, five days a week.
              </span>
            </Box>
          </Slide>
          <Slide duration={2000} direction="right">
            <Box className="features-types-single-container">
              <img src="icon-shield.svg" width={"140px"} alt="shield icon" />
              <h2 className="features-types-headers">Top-Level Security</h2>
              <span className="features-types-spans">
                We prioritize the security of your trading experience, <br />
                actively monitoring for and preventing unauthorized access.
              </span>
            </Box>
          </Slide>
        </Box>
      </Box>
    </>
  );
};

export default FeaturesPage;
