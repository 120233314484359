import * as React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "./index.scss";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableSortLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getFullLoginHistory } from "../../../../../redux/loginHistory/loginHistorySlice";
import { getUserUploadedFiles } from "../../../../../redux/files/uploadedFilesSlice";
import { userServices } from "../../../../../services/userServices";

function descendingComparator(a, b, orderBy) {
  if (
    orderBy === "amount" ||
    orderBy === "beforeTransaction" ||
    orderBy === "afterTransaction" ||
    orderBy === "createdAt" ||
    orderBy === "id"
  ) {
    if (+b[orderBy] < +a[orderBy]) {
      return -1;
    }
    if (+b[orderBy] > +a[orderBy]) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => {
    return [el, index];
  });
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  { id: "id", label: "Id", disablePadding: false },
  { id: "fileUrl", label: "File", disablePadding: false },
  { id: "action", label: "Action", disablePadding: false },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  // setInterval(() => {
  //   dispatch(getAllRegisteredUsers());
  // }, 10000);
  return (
    <TableHead style={{ background: "#3E4396" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{
              textWrap: "nowrap",
              textAlign: headCell.id !== "id" && "center",
            }}
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function UserUploadedDocuments({
  user,
  userDetails,
  setUserDetails,
}) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");

  const rows = useSelector((state) => state.files.files);
  const users = useSelector((state) => state.users.users);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const rowsToShow = !!user
    ? rows?.filter((row) => row?.userId === user?.id)
    : rows;
  const dispatch = useDispatch();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsToShow?.length) : 0;

  React.useEffect(() => {
    dispatch(getUserUploadedFiles(user?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const verifications = ["reset", "pending", "verified"];

  const handleVerifyPassport = async (e) => {
    const value = e.target.value;
    setUserDetails({
      ...userDetails,
      [e.target.name]: value,
    });
    await userServices.updateUser(user?.id, {
      verifiedPassport: value,
    });
  };

  const handleVerifyFunding = async (e) => {
    const value = e.target.value;
    setUserDetails({
      ...userDetails,
      [e.target.name]: value,
    });
    await userServices.updateUser(user?.id, {
      verifiedFunding: value,
    });
  };
  const handleVerifyAddress = async (e) => {
    const value = e.target.value;
    setUserDetails({
      ...userDetails,
      [e.target.name]: value,
    });
    await userServices.updateUser(user?.id, {
      verifiedAddress: value,
    });
  };
  const renderDropdownType = (row) => {
    if (row.fileName.toLowerCase() === "passport") {
      return (
        <FormControl>
          <Select
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              height: "35px",
              margin: "12px 2px",
              borderRadius: "2px",
              backgroundColor: "#1F2A40",
              width: "150px",
            }}
            value={userDetails.verifiedPassport}
            label="Verified Passport"
            name="verifiedPassport"
            onChange={(e) => handleVerifyPassport(e)}
          >
            {verifications.map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else if (row.fileName.toLowerCase() === "funding") {
      return (
        <FormControl>
          <Select
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              height: "35px",
              margin: "12px 2px",
              borderRadius: "2px",
              width: "150px",
              backgroundColor: "#1F2A40",
            }}
            value={userDetails.verifiedFunding}
            label="Verified Funding"
            name="verifiedFunding"
            onChange={(e) => handleVerifyFunding(e)}
          >
            {verifications.map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return (
        <FormControl>
          <Select
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              height: "35px",
              margin: "12px 2px",
              borderRadius: "2px",
              width: "150px",
              backgroundColor: "#1F2A40",
            }}
            value={userDetails.verifiedAddress}
            label="Verified Address"
            name="verifiedAddress"
            onChange={(e) => handleVerifyAddress(e)}
          >
            {verifications.map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
  };
  return (
    <div className="login-history-container-admin">
      {!user && <h1 className="login-history-header">Login History</h1>}
      {rowsToShow?.length > 0 ? (
        <Paper
          style={{
            width: !!user ? "40%" : "40%",
            maxHeight: "400px",
            overflowY: "hidden",
            margin: !!user ? "0 auto" : "0 2% auto",
            marginBottom: !!user ? "1rem" : "0",
          }}
        >
          <TableContainer>
            <Table
              sx={{ minWidth: 500, background: "#1F2A40" }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rowsToShow?.length}
              />
              <TableBody style={{ background: "#1F2A40" }}>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rowsToShow, getComparator(order, orderBy))
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    const foundUser = users?.find(
                      (user) => user?.id === row?.userId
                    );

                    return (
                      <TableRow
                        hover={true}
                        role="checkbox"
                        tabIndex={-1}
                        key={row?.id}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>

                        <TableCell
                          style={{ textWrap: "nowrap", textAlign: "center" }}
                        >
                          {" "}
                          <Button
                            color="info"
                            onClick={() =>
                              window.open(`${row.fileUrl}`, "_blank")
                            }
                          >
                            {row.fileName}
                          </Button>
                        </TableCell>
                        <TableCell>{renderDropdownType(row)}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ background: "#3E4396" }}
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={rowsToShow?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      ) : (
        <h3 style={{ color: "red" }}>No available login history...</h3>
      )}
    </div>
  );
}
