import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import EditUser from "./edit-user";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { visuallyHidden } from "@mui/utils";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
import { getAllAffiliates } from "../../../../redux/affiliate/affiliateSlice";
import {
  getAllAgents,
  getAllRegisteredUsers,
} from "../../../../redux/allRegisteredUsers/allRegisteredUsersSlice";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { getAllWithdrawHistory } from "../../../../redux/withdrawHistory/withdrawHistorySlice";
import { getAllTransactions } from "../../../../redux/transactions/transactionsSlice";
import "./users-table.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
import NotificationSound from "./notification.mp3";
import FatalitySound from "./fatality.mp3";
import Gogogo from "./gogogo.mp3";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { userServices } from "../../../../services/userServices";
import { getUser, getUserBalance } from "../../../../redux/users/userSlice";
import AddNewCommentModal from "./addNewCommentModal";
import { socket } from "../../../../context/socket";
import { Circle } from "@mui/icons-material";
import Skeleton from "react-loading-skeleton";
import Loader from "../../../../components/loader";

const countries = [
  "Sweden",
  "Norway",
  "Finland",
  "Denmark",
  "UK",
  "Netherlands",
];
const headCells = [
  { id: "action", disablePadding: false, label: "" },
  { id: "isOnline", disablePadding: false, label: "" },
  {
    id: "firstName",
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "lastName",
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "email",
    disablePadding: false,
    label: "Email",
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
  },
  {
    id: "phone",
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "country",
    disablePadding: false,
    label: "Country",
  },
  {
    id: "ballance",
    disablePadding: false,
    label: "Ballance",
  },
  {
    id: "comment",
    disablePadding: false,
    label: "Comment",
  },

  {
    id: "offerName",
    disablePadding: false,
    label: "Offer Name",
  },
  {
    id: "affiliateId",
    right: false,
    disablePadding: false,
    label: "Affiliate",
  },
  {
    id: "lastLogon",
    disablePadding: false,
    label: "Last Login",
  },

  {
    id: "registrationDate",
    disablePadding: false,
    label: "Register Date",
  },
  {
    id: "agent",
    disablePadding: false,
    label: "Agent",
  },
  {
    id: "previousAgent",
    disablePadding: false,
    label: "Previous Agent",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="users-table-head">
      <TableRow className="users-table-header-row">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className="users-table-header-cell"
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const initialFilterState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  country: [],
  status: [],
  agent: [],
  affiliate: [],
  fromDate: null,
  toDate: null,
};

export default function ColumnGroupingTable() {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("");
  const [page, setPage] = React.useState(0);
  const totalCount = useSelector((state) => state.users.totalCount);
  const [addNewCommentModal, setAddNewCommentModal] = React.useState(null);
  const audioPlayer = useRef(null);
  const fatalityPlayer = useRef(null);

  const [filterOptions, setFilterOptions] = React.useState(initialFilterState);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModal, setOpenModal] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const [userToEdit, setUserToEdit] = React.useState(null);
  const [selectedFiltersState, setSelectedFiltersState] =
    React.useState(filterOptions);
  const [doIWantToFilter, setDoIWantToFilter] = React.useState(false);
  const [multipleLeadsAgent, setMultipleLeadsAgent] =
    React.useState("Unassigned");
  const [filter, setFilter] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    country: [],
    status: [],
    agent: [],
    affiliate: [],
    phone: "",
  });

  const previousFilterOptionsThatNeedDelay = useRef({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const handleFilterChange = (filter, value) => {
    setFilterOptions((prev) => ({
      ...prev,
      [filter]: typeof value === "string" ? value.split(",") : value,
    }));
    setPage(0);
  };

  const handleResetFilters = () => {
    setFilterOptions(initialFilterState);
    setPage(0);
  };
  const users = useSelector((state) => state.users.users);
  const affiliates = useSelector((state) => state.users.affiliates);
  const dispatch = useDispatch();
  const reduxUser = useSelector(
    (state) => state.user && state.user.user && state.user.user.foundUser
  );

  const loggedInUserRole = reduxUser?.role;
  const statusOnLine = [
    "NO ANSWER",
    loggedInUserRole === "retention" || loggedInUserRole === "admin"
      ? "RET - NO ANSWER"
      : "",
    loggedInUserRole === "retention" || loggedInUserRole === "admin"
      ? "RET - NOT INTERESTED"
      : "",
    loggedInUserRole === "retention" || loggedInUserRole === "admin"
      ? "RET - CALL BACK"
      : "",
    loggedInUserRole === "retention" || loggedInUserRole === "admin"
      ? "RET - TRASH"
      : "",
    loggedInUserRole === "retention" || loggedInUserRole === "admin"
      ? "RET - LOW PRIORITY"
      : "",
    "HANG UP",
    "CALL BACK",
    "DVM",
    "DIALING",
    "FTD",
    "NEW LEAD",
    "DEPOSIT DECLINE",
    "INVALID",
    "UNDER AGED",
    "NOT INTERESTED",
    "HIGH PRORITY",
    "MEDIUM PRORITY",
    "LOW PRORITY",
    "UPSELL",
    "LANGUAGE ISSUES",
    "RECOVERY",
    "TRASH",
    "TRY AGAIN",
    "REASSIGNED",
    "NEW",
    "DUPLICATE",
    "RETENTION",
    "WRONG DETAILS",
  ].filter((status) => !!status);

  const status = [
    "NO ANSWER",
    "HANG UP",
    loggedInUserRole !== "head_of_conversion" &&
    loggedInUserRole !== "conversion"
      ? "RET - NO ANSWER"
      : "",
    loggedInUserRole !== "head_of_conversion" &&
    loggedInUserRole !== "conversion"
      ? "RET - NOT INTERESTED"
      : "",
    loggedInUserRole !== "head_of_conversion" &&
    loggedInUserRole !== "conversion"
      ? "RET - CALL BACK"
      : "",
    loggedInUserRole !== "head_of_conversion" &&
    loggedInUserRole !== "conversion"
      ? "RET - TRASH"
      : "",
    loggedInUserRole !== "head_of_conversion" &&
    loggedInUserRole !== "conversion"
      ? "RET - LOW PRIORITY"
      : "",
    "CALL BACK",
    "DVM",
    "DIALING",
    loggedInUserRole !== "head_of_conversion" &&
    loggedInUserRole !== "conversion"
      ? "FTD"
      : "",
    "NEW LEAD",
    "DEPOSIT DECLINE",
    "INVALID",
    "UNDER AGED",
    "NOT INTERESTED",
    "HIGH PRORITY",
    "MEDIUM PRORITY",
    "LOW PRORITY",
    "UPSELL",
    "LANGUAGE ISSUES",
    "RECOVERY",
    "TRASH",
    "TRY AGAIN",
    "REASSIGNED",
    "NEW",
    "DUPLICATE",
    loggedInUserRole !== "head_of_conversion" &&
    loggedInUserRole !== "conversion"
      ? "RETENTION"
      : "",
    "WRONG DETAILS",
  ].filter((status) => !!status);

  const loggedInUserName =
    reduxUser?.role !== "admin" &&
    reduxUser?.role !== "head_of_conversion" &&
    reduxUser?.role !== "crm_manager"
      ? reduxUser?.firstName + " " + reduxUser?.lastName
      : "";
  const [selected, setSelected] = React.useState([]);

  const rows = users;
  const returnCountry = (phone) => {
    const countryCodes = {
      44: "UK",
      31: "Netherlands",
      45: "Denmark",
      46: "Sweden",
      47: "Norway",
      35: "Finland",
      1: "Canada",
    };
    let firstDigits;
    if (phone[0] == 1) {
      firstDigits = phone?.slice(0, 1);
    } else {
      firstDigits = phone?.slice(0, 2);
    }
    const country = countryCodes[firstDigits] || "Unknown";
    return country;
  };

  const agents = useSelector((state) => state.users.agents)
    ?.map((agent) => `${agent?.firstName} ${agent?.lastName}`)
    ?.concat(["Unassigned"]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectDateRange = (date) => {
    setFilterOptions((prev) => ({
      ...prev,
      ["fromDate"]: date.selection.startDate,
      [`toDate`]: date.selection.endDate,
    }));
    setPage(0);
  };
  const selectionRange = {
    startDate: filterOptions.fromDate,
    endDate: filterOptions.toDate,
    key: "selection",
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (!!loggedInUserRole) {
      socket.on("newRegistration", (user) => {
        const selectedFilters = Object.fromEntries(
          Object.entries(filterOptions).filter(
            ([_, value]) => value !== "" && value !== null
          )
        );

        if (selectedFilters.fromDate) {
          selectedFilters.fromDate = new Date(
            selectedFilters.fromDate
          ).getTime();
        }
        if (selectedFilters.toDate) {
          selectedFilters.toDate = new Date(selectedFilters.toDate).getTime();
        }
        Object.keys(previousFilterOptionsThatNeedDelay.current).forEach(
          (k) =>
            (previousFilterOptionsThatNeedDelay.current[k] = filterOptions[k])
        );

        const payload = {
          ...selectedFilters,
          rowsPerPage,
          page: page + 1,
          loggedInUserName,
          loggedInUserRole,
          orderByQuery: orderBy,
          orderTypeQuery: order,
        };
        dispatch(getAllRegisteredUsers(payload));
        toast.success(`${user.email} has just registered!`, {
          position: "top-center",
          hideProgressBar: false,
          autoClose: false,
          closeOnClick: true,
          pauseOnHover: true,
          toastId: 4,
        });
      });

      socket.on("withdrawal", (withdrawal) => {
        toast.error(
          `User with Id:${withdrawal.userId} has just placed a withdrawal!`,
          {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            autoClose: false,
            pauseOnHover: true,
            toastId: 3,
          }
        );
      });

      socket.on("deposit", (email) => {
        toast.success(`User with email: ${email} has just placed a deposit!`, {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          autoClose: false,
          pauseOnHover: true,
          toastId: 3,
        });
      });

      socket.on("login", (user) => {
        toast.success(`User with email: ${user.email} has just logged in!`, {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          autoClose: false,
          pauseOnHover: true,
          toastId: 3,
        });
      });

      socket.on("activityChange", () => {
        const selectedFilters = Object.fromEntries(
          Object.entries(filterOptions).filter(
            ([_, value]) => value !== "" && value !== null
          )
        );

        if (selectedFilters.fromDate) {
          selectedFilters.fromDate = new Date(
            selectedFilters.fromDate
          ).getTime();
        }
        if (selectedFilters.toDate) {
          selectedFilters.toDate = new Date(selectedFilters.toDate).getTime();
        }
        Object.keys(previousFilterOptionsThatNeedDelay.current).forEach(
          (k) =>
            (previousFilterOptionsThatNeedDelay.current[k] = filterOptions[k])
        );

        const payload = {
          ...selectedFilters,
          rowsPerPage,
          page: page + 1,
          loggedInUserName,
          loggedInUserRole,
          orderByQuery: orderBy,
          orderTypeQuery: order,
        };
        dispatch(getAllRegisteredUsers(payload));
      });

      socket.on("closedOrder", ({ userId, closeOrder }) => {
        if (closeOrder) {
          console.log("ISNDEI CLOSED ORDER");
          dispatch(getUserBalance(userId));
        }
      });
    }
    return () => {
      socket.off("newRegistration", () => {
        console.log("Socket off!");
      });
      socket.off("withdrawal", () => {
        console.log("Socket off!");
      });
      socket.off("deposit", () => {
        console.log("Socket off!");
      });
      socket.off("login", () => {
        console.log("Socket off!");
      });
      socket.off("activityChange", () => {
        console.log("Socket off");
      });
      socket.off("closedOrder", () => {
        console.log("Socket off");
      });
    };
  }, [socket]);

  React.useEffect(() => {
    const itNeedsDelay = Object.keys(
      previousFilterOptionsThatNeedDelay.current
    ).some(
      (key) =>
        filterOptions[key] !== previousFilterOptionsThatNeedDelay.current[key]
    );

    const selectedFilters = Object.fromEntries(
      Object.entries(filterOptions).filter(
        ([_, value]) => value !== "" && value !== null
      )
    );

    if (selectedFilters.fromDate) {
      selectedFilters.fromDate = new Date(selectedFilters.fromDate).getTime();
    }
    if (selectedFilters.toDate) {
      selectedFilters.toDate = new Date(selectedFilters.toDate).getTime();
    }
    Object.keys(previousFilterOptionsThatNeedDelay.current).forEach(
      (k) => (previousFilterOptionsThatNeedDelay.current[k] = filterOptions[k])
    );

    setSelectedFiltersState(selectedFilters);
    const payload = {
      ...selectedFilters,
      rowsPerPage,
      page: page + 1,
      loggedInUserName,
      loggedInUserRole,
      orderByQuery: orderBy,
      orderTypeQuery: order,
    };

    if (!itNeedsDelay) {
      dispatch(getAllRegisteredUsers(payload));
      dispatch(getAllAgents());
      return;
    } else {
      const timeout = setTimeout(() => {
        dispatch(getAllRegisteredUsers(payload));
        dispatch(getAllAgents());
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [page, rowsPerPage, filterOptions, dispatch, order, orderBy]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  const handleLeadAgentChange = (row, e) => {
    const value = e.target.value;
    userServices
      .changeUserAgent(row.id, {
        agent: value,
      })
      .then(() => {
        const selectedFilters = Object.fromEntries(
          Object.entries(filterOptions).filter(
            ([_, value]) => value !== "" && value !== null
          )
        );

        if (selectedFilters.fromDate) {
          selectedFilters.fromDate = new Date(
            selectedFilters.fromDate
          ).getTime();
        }
        if (selectedFilters.toDate) {
          selectedFilters.toDate = new Date(selectedFilters.toDate).getTime();
        }
        Object.keys(previousFilterOptionsThatNeedDelay.current).forEach(
          (k) =>
            (previousFilterOptionsThatNeedDelay.current[k] = filterOptions[k])
        );

        const payload = {
          ...selectedFilters,
          rowsPerPage,
          page: page + 1,
          loggedInUserRole,
          loggedInUserName,
          orderByQuery: orderBy,
          orderTypeQuery: order,
        };
        dispatch(getAllRegisteredUsers(payload));
      });
  };

  const handleMultipleLeadsAgentChange = (e) => {
    const agent = e.target.value;
    userServices
      .changeMultipleUsersAgent({
        userIds: selected,
        agent,
      })
      .then(() => {
        const selectedFilters = Object.fromEntries(
          Object.entries(filterOptions).filter(
            ([_, value]) => value !== "" && value !== null
          )
        );

        if (selectedFilters.fromDate) {
          selectedFilters.fromDate = new Date(
            selectedFilters.fromDate
          ).getTime();
        }
        if (selectedFilters.toDate) {
          selectedFilters.toDate = new Date(selectedFilters.toDate).getTime();
        }
        Object.keys(previousFilterOptionsThatNeedDelay.current).forEach(
          (k) =>
            (previousFilterOptionsThatNeedDelay.current[k] = filterOptions[k])
        );

        const payload = {
          ...selectedFilters,
          rowsPerPage,
          page: page + 1,
          loggedInUserName,
          loggedInUserRole,
          orderByQuery: orderBy,
          orderTypeQuery: order,
        };
        dispatch(getAllRegisteredUsers(payload));
        setSelected([]);
      });
  };
  const handleLeadStatusChange = (row, e) => {
    const value = e.target.value;
    userServices
      .changeUserStatus(row.id, {
        status: value,
      })
      .then(() => {
        const selectedFilters = Object.fromEntries(
          Object.entries(filterOptions).filter(
            ([_, value]) => value !== "" && value !== null
          )
        );

        if (selectedFilters.fromDate) {
          selectedFilters.fromDate = new Date(
            selectedFilters.fromDate
          ).getTime();
        }
        if (selectedFilters.toDate) {
          selectedFilters.toDate = new Date(selectedFilters.toDate).getTime();
        }
        Object.keys(previousFilterOptionsThatNeedDelay.current).forEach(
          (k) =>
            (previousFilterOptionsThatNeedDelay.current[k] = filterOptions[k])
        );

        const payload = {
          ...selectedFilters,
          rowsPerPage,
          page: page + 1,
          loggedInUserName,
          loggedInUserRole,
          orderByQuery: orderBy,
          orderTypeQuery: order,
        };
        dispatch(getAllRegisteredUsers(payload));
      });
  };

  const handleAffiliateChange = (event) => {
    const {
      target: { value, name },
    } = event;
    setFilter({
      ...filter,
      [name]: typeof value === "string" ? value.split(",") : value,
    });
    // On autofill we get a stringified value.
  };

  function removeByIndex(str, index) {
    return str.slice(0, index) + str.slice(index + 1);
  }

  const scrollToTop = () => {
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const handleClick = (event, userId) => {
    // handleCopyToClipBoard(event);
    // dispatch(getUserBalance(userId.id));
    setUserToEdit(userId);
    setOpenModal(true);
    scrollToTop();
  };

  const greenColorStatuses = [
    "FTD",
    "RETENTION",
    "FOLLOW UP",
    "NEW LEAD",
    "TRY AGAIN",
  ];

  const handleRowClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isRowSelected = (id) => selected.indexOf(id) !== -1;
  const fetchUsersPayload = {
    ...selectedFiltersState,
    rowsPerPage,
    page: page + 1,

    loggedInUserName,
    loggedInUserRole,
    orderByQuery: orderBy,
    orderTypeQuery: order,
  };
  return (
    <>
      <Loader loading={showLoader} />
      <Box
        style={{
          padding: "5px",
          margin: "0 auto",
          background: "#121c31",
          textAlign: "center",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {selected?.length ? (
          <FormControl style={{ minWidth: "150px", marginRight: "1rem" }}>
            <InputLabel
              style={{ color: "white", fontSize: "1rem", paddingTop: "0rem" }}
            >
              Selected leads' agent
            </InputLabel>
            <Select
              disabled={!!loggedInUserName}
              style={{
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                height: "38px",
                margin: "12px 2px",
                backgroundColor: "#1F2A40",
              }}
              value={multipleLeadsAgent}
              name="agent"
              onChange={(e) => {
                handleMultipleLeadsAgentChange(e);
              }}
            >
              {agents?.map((v) => (
                <MenuItem key={v} value={v === "Unassigned" ? "" : v}>
                  {v}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <></>
        )}
        {!!rows ? (
          <TextField
            className="edit-user-textField"
            value={
              filterOptions["firstName"] ? filterOptions["firstName"] : null
            }
            label="First Name"
            variant="outlined"
            name="firstName"
            style={{ marginRight: "1rem" }}
            type="text"
            onChange={(e) => handleFilterChange("firstName", e.target.value)}
            InputLabelProps={{
              style: { color: "white", top: "-40%" },
            }}
            InputProps={{
              style: {
                color: "white",
              },
            }}
          />
        ) : (
          <Skeleton style={{ marginRight: "1rem" }} height={50} width={180} />
        )}
        {!!rows ? (
          <TextField
            type="text"
            className="edit-user-textField"
            value={filterOptions["lastName"] ? filterOptions["lastName"] : null}
            style={{ marginRight: "1rem" }}
            label="Last Name"
            variant="outlined"
            name="lastName"
            onChange={(e) => handleFilterChange("lastName", e.target.value)}
            InputLabelProps={{
              style: { color: "white", top: "-40%" },
            }}
            InputProps={{
              style: {
                color: "white",
              },
            }}
          />
        ) : (
          <Skeleton style={{ marginRight: "1rem" }} height={50} width={180} />
        )}
        {!!rows ? (
          <TextField
            className="edit-user-textField"
            value={filterOptions["email"] ? filterOptions["email"] : null}
            label="Email"
            variant="outlined"
            type="text"
            style={{
              backgroundColor: "#1F2A40",
              color: "white",
              marginRight: "1rem",
            }}
            name="email"
            onChange={(e) => handleFilterChange("email", e.target.value)}
            InputLabelProps={{
              style: { color: "white", top: "-40%" },
            }}
            InputProps={{
              style: {
                color: "white",
              },
            }}
          />
        ) : (
          <Skeleton style={{ marginRight: "1rem" }} height={50} width={180} />
        )}
        {!!rows ? (
          <TextField
            className="edit-user-textField"
            value={filterOptions["phone"] ? filterOptions["phone"] : null}
            label="Phone"
            variant="outlined"
            type="text"
            style={{ backgroundColor: "#1F2A40", color: "white" }}
            name="phone"
            onChange={(e) => handleFilterChange("phone", e.target.value)}
            InputLabelProps={{
              style: { color: "white", top: "-40%" },
            }}
            InputProps={{
              style: {
                color: "white",
              },
            }}
          />
        ) : (
          <Skeleton style={{ marginRight: "1rem" }} height={50} width={180} />
        )}
        {!!rows ? (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel
              style={{ color: "white", fontSize: "1rem", paddingTop: "0rem" }}
            >
              Country
            </InputLabel>
            <Select
              name="country"
              multiple
              value={filterOptions["country"] ? filterOptions["country"] : null}
              label="Country"
              inputProps={{
                style: {
                  color: "white",
                },
              }}
              onChange={(e) => handleFilterChange("country", e.target.value)}
              style={{ backgroundColor: "#1F2A40", color: "white" }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
            >
              {countries?.map((country, i) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Skeleton style={{ marginRight: "1rem" }} height={50} width={100} />
        )}
        {!!rows ? (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel
              style={{ color: "white", fontSize: "1rem", paddingTop: "0rem" }}
            >
              Status
            </InputLabel>
            <Select
              name="status"
              multiple
              value={filterOptions["status"] ? filterOptions["status"] : null}
              label="Status"
              inputProps={{
                style: {
                  color: "white",
                },
              }}
              onChange={(e) => handleFilterChange("status", e.target.value)}
              style={{ backgroundColor: "#1F2A40", color: "white" }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
            >
              {status?.map((status, i) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Skeleton style={{ marginRight: "1rem" }} height={50} width={100} />
        )}
        {!!rows ? (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel style={{ color: "white", fontSize: "1rem" }}>
              Agent
            </InputLabel>
            <Select
              name="agent"
              multiple
              inputProps={{
                style: {
                  color: "white",
                },
              }}
              disabled={!!loggedInUserName}
              value={filterOptions["agent"] ? filterOptions["agent"] : null}
              label="Agent"
              onChange={(e) => handleFilterChange("agent", e.target.value)}
              style={{ backgroundColor: "#1F2A40", color: "white" }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
            >
              {agents?.map((agent, i) => (
                <MenuItem key={agent} value={agent}>
                  {agent}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Skeleton style={{ marginRight: "1rem" }} height={50} width={100} />
        )}
        {!!rows ? (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel style={{ color: "white", fontSize: "1rem" }}>
              Affiliate
            </InputLabel>
            <Select
              inputProps={{
                style: {
                  color: "white",
                },
              }}
              name="affiliate"
              multiple
              value={
                filterOptions["affiliate"] ? filterOptions["affiliate"] : null
              }
              label="Affiliate"
              onChange={(e) => handleFilterChange("affiliate", e.target.value)}
              style={{ backgroundColor: "#1F2A40", color: "white" }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
            >
              {affiliates?.map((affiliate, i) => (
                <MenuItem key={affiliate?.id} value={affiliate?.id}>
                  {affiliate?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Skeleton style={{ marginRight: "1rem" }} height={50} width={100} />
        )}
        {!!rows ? (
          <Button
            style={{
              height: "40px",
              fontSize: "12px",
              background: "#3DA58A",
              fontWeight: "bolder",
              color: "white",
              marginRight: "0.8rem",
            }}
            variant="contained"
            onClick={() => setDoIWantToFilter((prev) => !prev)}
          >
            Filter by Date
          </Button>
        ) : (
          <Skeleton style={{ marginRight: "1rem" }} height={50} width={150} />
        )}
        {!!rows ? (
          <Button
            style={{
              height: "40px",
              fontSize: "12px",
              background: "#3DA58A",
              fontWeight: "bolder",
              color: "white",
            }}
            // color="success"
            variant="contained"
            onClick={() => handleResetFilters()}
          >
            Reset Filters
          </Button>
        ) : (
          <Skeleton style={{ marginRight: "1rem" }} height={50} width={150} />
        )}
        {!!doIWantToFilter && (
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleSelectDateRange}
          />
        )}
      </Box>
      <audio
        ref={audioPlayer}
        src={NotificationSound}
        style={{ display: "none" }}
      ></audio>
      <Box sx={{ width: "100%" }}></Box>
      <audio
        ref={fatalityPlayer}
        src={FatalitySound}
        style={{ display: "none" }}
      ></audio>
      <Box sx={{ width: "100%" }}></Box>
      {!!rows ? (
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer style={{ overflowY: "auto" }}>
              <Table
                sx={{ minWidth: 750, background: "#1F2A40" }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={totalCount}
                />
                <TableBody style={{ background: "#1F2A40 " }}>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  {/* {stableSort(rows, getComparator(order, orderBy)) */}
                  {/* // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
                  {rows?.map((row, index) => {
                    const isItemSelected = isRowSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${rows?.id}`;
                    const commentSpliced =
                      row?.comment?.length > 70
                        ? row?.comment?.slice(0, 70)?.concat("...")
                        : row?.comment;
                    const noteSliced =
                      !!row.notes &&
                      row?.notes[row?.notes?.length - 1].note?.length > 70
                        ? row?.notes[row?.notes?.length - 1]?.note
                            ?.slice(0, 70)
                            ?.concat("...")
                        : !row?.notes
                        ? "---"
                        : row?.notes[row?.notes?.length - 1]?.note;
                    const affiliatesMapped = affiliates?.map((affiliate) => {
                      return {
                        id: affiliate?.id,
                        name: affiliate?.name,
                      };
                    });
                    return (
                      <TableRow
                        hover={true}
                        role="checkbox"
                        tabIndex={-1}
                        key={row?.id}
                        selected={isItemSelected}

                        // style={{backgroundColor: '#1F2A40'}}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              handleRowClick(event, row?.id);
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          <Circle color={row?.isOnline ? "success" : "error"} />
                        </TableCell>
                        <TableCell
                          component="th"
                          align="center"
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          {row.firstName}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          {row.lastName}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            cursor: "pointer",
                            color: "#3DA58A",
                            textDecoration: "underline",
                            padding: "0",
                          }}
                          onClick={(event) => handleClick(event, row)}
                        >
                          {row.email}
                        </TableCell>
                        <TableCell align="center">
                          <FormControl style={{ minWidth: "100px" }}>
                            <Select
                              style={{
                                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                                height: "28px",
                                margin: "12px 2px",
                                backgroundColor: "#1F2A40 !important",
                                fontWeight: "bold",
                                padding: "0",
                                color: greenColorStatuses.includes(row.status)
                                  ? "green"
                                  : row.status === "DIALING"
                                  ? "yellow"
                                  : "red",
                              }}
                              value={!row.status ? " " : row.status}
                              name="status"
                              onChange={(e) => handleLeadStatusChange(row, e)}
                              MenuProps={{ style: { height: "400px" } }}
                            >
                              {statusOnLine.map((v) => (
                                <MenuItem key={v} value={v}>
                                  {v}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          <a
                            href={`tel:${
                              row.phone.toString().charAt(2) === "0"
                                ? removeByIndex(row.phone.toString(), 2)
                                : row.phone
                            }`}
                          >
                            {row.phone.toString().charAt(2) === "0"
                              ? removeByIndex(row.phone.toString(), 2)
                              : row.phone}
                          </a>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          {returnCountry(row.phone)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          {row.ballance}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "#ffbf00",
                            padding: "0",
                            position: "relative",
                            cursor: "pointer",
                          }}
                          onClick={() => setAddNewCommentModal(row)}
                        >
                          {!!row.notes
                            ? noteSliced
                            : !!row.comment && !row.notes?.length
                            ? commentSpliced
                            : "---"}
                        </TableCell>

                        {/* <TableCell align="center">{row.country}</TableCell> */}

                        {(reduxUser?.role === "admin" ||
                          reduxUser?.role === "retention" ||
                          reduxUser?.role === "conversion" ||
                          reduxUser?.role === "crm_manager" ||
                          reduxUser?.role === "head_of_conversion") && (
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "#b8b8b8",
                              padding: "0",
                            }}
                          >
                            {row.offerName}
                          </TableCell>
                        )}

                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          {
                            affiliatesMapped?.find(
                              (affiliate) => affiliate?.id === row.affiliateId
                            )?.name
                          }
                        </TableCell>
                        {/* <TableCell align="center" style={{fontWeight: 'bold'}}>{row.comment}</TableCell> */}
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          {row.lastLogon &&
                            new Date(+row.lastLogon).toLocaleString("sv-SE", {
                              timeZone: "Europe/Stockholm",
                            })}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          {row.registrationDate &&
                            new Date(+row.registrationDate).toLocaleString(
                              "sv-SE",
                              {
                                timeZone: "Europe/Stockholm",
                              }
                            )}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          <FormControl style={{ minWidth: "150px" }}>
                            {/* <InputLabel style={{ color: "white" }}>
    Agent
  </InputLabel> */}
                            <Select
                              style={{
                                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                                height: "28px",
                                margin: "12px 2px",
                                backgroundColor: "#1F2A40",
                                // border: "1px solid gray",
                                // borderRadius: "2px",
                              }}
                              value={!row.agent ? "Unassigned" : row.agent}
                              // label="Agent"
                              name="agent"
                              onChange={(e) => {
                                e.stopPropagation();
                                handleLeadAgentChange(row, e);
                              }}
                            >
                              {agents?.map((v) => (
                                <MenuItem key={v} value={v}>
                                  {v}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            padding: "0",
                          }}
                        >
                          {!row.previousAgent
                            ? "Unassigned"
                            : row.previousAgent}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {/* {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={{ background: "#3E4396" }}
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              // onChange={onPageChangePagination}
            />
          </Paper>
          {openModal && (
            <EditUser
              setShowLoader={setShowLoader}
              showLoader={showLoader}
              user={userToEdit}
              setOpenModal={setOpenModal}
              fetchUsersPayload={fetchUsersPayload}
            />
          )}
          {!!addNewCommentModal && (
            <AddNewCommentModal
              fetchUsersPayload={fetchUsersPayload}
              addNewCommentModal={addNewCommentModal}
              setAddNewCommentModal={setAddNewCommentModal}
            />
          )}
        </Box>
      ) : (
        <Skeleton count={10} height={70} />
      )}

      <ToastContainer />
    </>
  );
}
