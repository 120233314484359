import { configureStore } from "@reduxjs/toolkit";
import cryptoMarketDataReducer from "./cryptoMarketData/reducer";
import favourites from "./favourites/reducer";
import userSlice from "./users/userSlice";
import orderSlice from "./orders/orderSlice";
import affiliateSlice from "./affiliate/affiliateSlice";
import transactionsSlice from "./transactions/transactionsSlice";
import withdrawHistorySlice from "./withdrawHistory/withdrawHistorySlice";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { websocketSlice } from "./cryptoWebsocket/websocketSlice";
import allRegisteredUsersSlice from "./allRegisteredUsers/allRegisteredUsersSlice";
import sidebarSlice from "./sidebar/index";
import loginHistorySlice from "./loginHistory/loginHistorySlice";
import uploadedFilesSlice from "./files/uploadedFilesSlice";
const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, userSlice);

export const store = configureStore({
  reducer: {
    cryptoMarketDataReducer,
    favourites,
    orders: orderSlice,
    websocket: websocketSlice,
    transactions: transactionsSlice,
    withdrawHistory: withdrawHistorySlice,
    user: persistedReducer,
    users: allRegisteredUsersSlice,
    affiliate: affiliateSlice,
    sidebar: sidebarSlice,
    loginHistory: loginHistorySlice,
    files: uploadedFilesSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);
